import axios from "axios";

const URL = "https://api.openweathermap.org/data/2.5/weather";
const API_KEY = process.env.REACT_APP_WEATHER_API_KEY;

export const fetchWeather = async (query) => {
    const { data } = await axios
      .get(URL, {
        params: {
          q: query,
          units: "imperial",
          APPID: API_KEY,
        },
      })
      .catch(function (error) {
        console.clear()
      });

    return data;
  
};
